<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Info Empresa</label>
        <img src="../../../assets/img/icon/close_popup.png" @click="cerrar()" />
        <div class="popup-top-btn-container">
          <div
            class="popup-top-btn"
            @click="mostrarEliminar = true"
            v-if="
              usuario != null &&
              !usuario.rol.some((r) => r == 'supervisor' || r == 'intendente')
            "
          >
            <img src="../../../assets/img/popup/delete.png" />
          </div>
          <div
            class="popup-top-btn"
            @click="editar()"
            v-if="
              usuario != null && !usuario.rol.some((r) => r == 'supervisor')
            "
          >
            <img src="../../../assets/img/popup/edit.png" />
          </div>
        </div>
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label">Nombre de la empresa</label>
          <label>{{ empresa.nombre }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label">CUIT</label>
          <label>{{ empresa.CUIT }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label">Mail de contacto</label>
          <label>{{ empresa.email }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label">Vigilancias vinculadas</label>
          <label
            v-show="
              empresa.vigilancias == null || empresa.vigilancias.length == 0
            "
            >0 vigilancias</label
          >
          <div v-for="vigilancia in empresa.vigilancias" :key="vigilancia.id">
            <label>{{ vigilancia.nombre }}</label>
          </div>
        </div>
        <div class="popup-row">
          <label class="popup-label">Usuarios vinculados</label>
          <label v-show="empresa.users == null || empresa.users.length == 0"
            >0 usuarios</label
          >
          <div v-for="user in empresa.users" :key="user.id">
            <label>{{
              user.nombre + " " + user.apellido + " (" + user.username + ")"
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <Eliminar
      :mostrar="mostrarEliminar"
      :tipo="'Empresa'"
      :nombre="empresa.nombre + ', los usuarios serán desvinculados'"
      @cerrar="eliminarCerrar()"
      @aceptar="eliminarAceptar()"
    ></Eliminar>
  </div>
</template>

<script>
import Eliminar from "@/components/Eliminar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Info_Vigilancia",
  components: { Eliminar },
  props: {
    empresa: Object,
  },
  data() {
    return {
      mostrarEliminar: false,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {},
  methods: {
    editar() {
      this.$emit("editar", this.empresa);
      this.cerrar();
    },
    cerrar() {
      this.$emit("cerrar");
    },
    eliminarCerrar() {
      this.mostrarEliminar = false;
    },
    eliminarAceptar() {
      this.mostrarEliminar = false;
      var that = this;
      this.$axios
        .delete(this.$serverURL + "/api/empresas/" + this.empresa.id + "/")
        .then(function (response) {
          console.log(response);
          that.$emit("get");
          that.cerrar();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
<style scoped lang="scss" src="@/assets/css/views/admin/empresas.scss">
</style>
