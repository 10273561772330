<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>{{ titulo }}</label>
        <img
          src="../../../assets/img/icon/close_popup.png"
          @click="mostrarSalir = true"
        />
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label>Nombre de la empresa (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorNombre }"
          >
            <input
              type="text"
              placeholder="Escribe nombre de la empresa"
              v-model="nombre"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>CUIT (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorCUIT }"
          >
            <input
              type="text"
              placeholder="Escribe CUIT"
              v-model="cuit"
              @keyup="onlyNumbers"
              @keypress="onlyNumbersPress"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>Mail de contacto (*)</label>
          <div
            class="input input-primary"
            :class="{ 'input-error': errorEmail }"
          >
            <input type="text" placeholder="Escribe mail" v-model="email" />
          </div>
        </div>
      </div>
      <div class="popup-bot-border">
        <button
          v-show="!guardando"
          class="button button-primary popup-btn-continuar-chico"
          @click="finalizar()"
        >
          Finalizar
        </button>
        <button
          v-show="guardando"
          class="button button-ok popup-btn-continuar-chico"
        >
          Finalizando
        </button>
      </div>
    </div>
    <Salir
      :mostrar="mostrarSalir"
      :tipo="titulo.toLowerCase()"
      @cerrar="mostrarSalir = false"
      @aceptar="cerrar()"
    >
    </Salir>
  </div>
</template>

<script>
import Salir from "@/components/Salir.vue";

export default {
  name: "Nueva_Empresa",
  components: { Salir },
  data() {
    return {
      titulo: "Crear nueva empresa",
      id: null,
      nombre: "",
      cuit: "",
      email: "",
      password: "",
      vigilancias: [],
      errorNombre: false,
      errorCUIT: false,
      errorEmail: "",
      guardando: false,
      mostrarSalir: false,
    };
  },
  mounted() {
    this.$emitter.on("empresas-editar", (email) => this.editar(email));
  },
  beforeUnmount() {
    this.$emitter.off("empresas-editar");
  },
  methods: {
    onlyNumbers($event) {
      var key = $event.currentTarget.value.substr(
        $event.currentTarget.value.length - 1,
        1
      );
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.currentTarget.value = $event.currentTarget.value.substr(
          0,
          $event.currentTarget.value.length - 1
        );
      }
    },
    onlyNumbersPress($event) {
      var key = $event.key;
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.preventDefault();
      }
    },
    editar(empresa) {
      this.titulo = "Editar empresa";
      this.nombre = empresa.nombre;
      this.cuit = empresa.CUIT;
      this.email = empresa.email;
      this.id = empresa.id;
      this.vigilancias = empresa.vigilancias;
    },
    finalizar() {
      this.guardando = true;
      let continuar = true;

      if (this.nombre == "") {
        this.errorNombre = true;
        continuar = false;
      } else {
        this.errorNombre = false;
      }

      if (this.cuit == "") {
        this.errorCUIT = true;
        continuar = false;
      } else {
        this.errorCUIT = false;
      }

      if (this.email == "" || !this.validateEmail(this.email)) {
        this.errorEmail = true;
        continuar = false;
      } else {
        this.errorEmail = false;
      }

      if (continuar) {
        let json = {
          id: this.id,
          CUIT: parseFloat(this.cuit),
          nombre: this.nombre,
          email: this.email,
          vigilancias: this.vigilancias,
        };
        var that = this;
        if (this.id != null) {
          this.$axios
            .put(this.$serverURL + "/api/empresas/" + this.id + "/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        } else {
          console.log(json);
          this.$axios
            .post(this.$serverURL + "/api/empresas/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              console.log(response.response.data);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        }
      } else {
        this.guardando = false;
      }
    },
    cerrar() {
      this.$emit("cerrar");
      this.titulo = "Crear nueva empresa";
      this.limpiar();
      this.seccion = 1;
      this.mostrarSalir = false;
    },
    limpiar() {
      this.nombre = "";
      this.cuit = "";
      this.email = "";
      this.vigilancias = [];
      this.id = null;
    },
    validateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
