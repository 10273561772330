<template>
  <div class="view">
    <Topbar :seccion="'empresas'"></Topbar>
    <div class="view-container">
      <div class="secciones-top">
        <label class="secciones-title">Empresas</label>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../../assets/img/icon/close.png"
            @click="resetBuscar()"
          />
          <img
            class="secciones-buscar-img"
            src="../../assets/img/icon/lupa.png"
          />
        </div>
      </div>
      <div class="secciones-empresas">
        <div
          v-for="empresa in empresasFiltradas"
          :key="empresa.id"
          class="empresas-empresa"
          @click="elegirEmpresa(empresa)"
        >
          <img class="empresa-img" src="../../assets/img/icon/empresa.png" />
          <div>
            <label class="empresa-nombre">{{ empresa.nombre }}</label>
            <label class="empresa-etiqueta">{{ empresa.etiqueta }}</label>
            <label class="empresa-label-active"
              >{{ empresa.vigilancias.length }} Vigilancias</label
            >
            <label class="empresa-label-active"
              >{{
                empresa.users != null ? empresa.users.length : "0"
              }}
              Usuarios</label
            >
          </div>
        </div>
      </div>
      <button
        class="button button-primary secciones-crear-btn"
        v-if="usuario != null && !usuario.rol.some((r) => r == 'supervisor')"
        @click="mostrarNuevo = true"
      >
        <img src="../../assets/img/icon/empresa_white.png" />Crear nueva empresa
      </button>
    </div>
    <Nuevo
      v-show="mostrarNuevo"
      @cerrar="cerrarNuevo()"
      @get="getEmpresas()"
    ></Nuevo>
    <Info
      v-if="mostrarInfo"
      :empresa="empresaElegida"
      @cerrar="cerrarInfo()"
      @editar="editar"
      @get="getEmpresas()"
    >
    </Info>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Nuevo from "@/components/admin/empresas/Nuevo.vue";
import Info from "@/components/admin/empresas/Info.vue";
import { mapGetters } from "vuex";

export default {
  name: "Empresas",
  components: { Topbar, Nuevo, Info },
  data() {
    return {
      searchText: "",
      empresas: [],
      empresasFiltradas: [],
      mostrarNuevo: false,
      mostrarInfo: false,
      empresaElegida: null,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {
    this.getEmpresas();
  },
  methods: {
    cerrarNuevo() {
      this.mostrarNuevo = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    elegirEmpresa(empresa) {
      this.empresaElegida = empresa;
      this.mostrarInfo = true;
    },
    editar(empresa) {
      this.mostrarNuevo = true;
      this.$emitter.emit("empresas-editar", empresa);
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          console.log(response);
          that.empresas = response.data;
          that.empresasFiltradas = that.empresas;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    resetBuscar() {
      this.searchText = "";
      this.filtrar();
    },
    filtrar() {
      let filtroBuscar = this.searchText.toLowerCase();

      this.empresasFiltradas = this.empresas.filter(
        (e) =>
          this.searchText == "" ||
          (e.nombre != null &&
            e.nombre.toLowerCase().indexOf(filtroBuscar) > -1)
      );
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
<style scoped lang="scss" src="@/assets/css/views/admin/empresas.scss">
</style>
